import React from "react"
import { Link } from "gatsby"
import { LazyLoadImage } from "react-lazy-load-image-component"
import Layout from "../components/layout"
import "../components/layout.css"
import SEO from "../components/seo"
import logo from "../images/360logo2.png"
import competencies from "../images/GLA-Competency-Descriptions.pdf"
import values from "../images/BKM_Personal_Values.pdf"
import leadership from "../images/Brittany_Macdonald_Authentic_Leadership_Model.pdf"

const ThreeSixtyReview = () => (
	<Layout>
		<SEO
			title="360 Degree Review & Assessment - Executive Coaching Companies"
			description="360 degree reviews & assessments are an integral part of uncovering your truths. Help your team grow with AGL Coaching for Good today! We truly want you to prosper."
		/>
		<div className="page-headline">
			<div className="container ">
				<div className="section-heading text-center">
					<h1>
						<strong>360 Degree Review & Assessment</strong>
					</h1>
				</div>
			</div>
			<div className="headline-bg" />
		</div>
		<div className="page-content">
			<div className="container">
				<div className="row justify-content-between pt-5">
					<div className="col-md-3 mx-auto">
						<LazyLoadImage
							className="engager-icon mr-3 img-fluid"
							src={logo}
							alt="Engager Icon"
						/>
					</div>
					<div className="col-md-8 mx-auto">
						<div className="container">
							<div className="row justify-content-center">
								<div className="col-md-12">
									<h2 className="mb-0">
										<strong>Wisdom. Courage. Humility. </strong>{" "}
									</h2>
									<p className="">
										On every journey, in every story, these are the gems a
										character seeks. Through trials, challenges, adventures, and
										guides, our hero slowly discovers what is most true of
										themselves. Surprisingly, they often learn they had these
										qualities all along. Perhaps it just required an outside
										observer or event for them to see it.
									</p>
									<p className="">
										As you grow on your journey, we invite you to your 360
										degree review and assessment to uncover and celebrate what
										is already true about you.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="page-content wch-bg">
			<div className="container">
				<div className="row justify-content-between pt-5 p-2">
					<div className="col-md-6 mx-auto">
						<h2 className="mb-0	text-white">
							<strong>Your Tribe.</strong>{" "}
						</h2>
						<p className="text-white">
							Start by finding your tribe; the ones who know you and can see
							your beauty shining through.
						</p>
						<p className="text-white">
							These friends - colleagues, peers, family, mentors - should also
							be able to see the blind spots; the ones that keep us stuck on our
							journey or that might prove fatal if we meet a foe unprepared.
						</p>
					</div>
					<div className="col-md-6 mx-auto"></div>
				</div>
			</div>
		</div>
		<div className="page-content">
			<div className="container">
				<div className="row justify-content-between pt-5">
					<div className="col-md-3 mx-auto" />
					<div className="col-md-8 mx-auto">
						<div className="container">
							<div className="row justify-content-center">
								<div className="col-md-12">
									<h2 className="mb-0 text-gold">
										<strong>Your Guide.</strong>{" "}
									</h2>
									<p className="">
										Once your tribe has shared their insights through an
										anonymous survey, you will walk with a guide who will help
										you interpret what you have received.
									</p>
									<p className="">
										This part of the journey requires courage and humility. It
										takes both to receive the insights of others and allow them
										to take root and grow within us.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="page-content treasure-bg">
			<div className="container">
				<div className="row justify-content-between pt-5 p-2">
					<div className="col-md-6 mx-auto">
						<h2 className="mb-0	text-white">
							<strong>Uncovering Treasure.</strong>{" "}
						</h2>
						<p className="text-white">
							As you walk with your guide, they will help you uncover the
							treasure that lies deep within you. Through individual feedback
							and exploring your personal values, you will unearth the treasures
							that have been inside you all along.
						</p>
					</div>
					<div className="col-md-6 mx-auto"></div>
				</div>
			</div>
		</div>
		<div className="page-content">
			<div className="container">
				<div className="row justify-content-between pt-5">
					<div className="col-md-3 mx-auto" />
					<div className="col-md-8 mx-auto">
						<div className="container">
							<div className="row justify-content-center">
								<div className="col-md-12">
									<h2 className="mb-0 text-gold">
										<strong>Create a Roadmap</strong>{" "}
									</h2>
									<p className="">
										At the end of your time, you will develop a roadmap to
										encapsulate your newfound wisdom into one page to guide you
										on the path ahead.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="page-content bg-gold">
			<div className="container">
				<div className="row justify-content-between pt-5">
					<div className="col-md-10 mx-auto">
						<div className="text-center">
							<h2 className="mb-0	text-white">
								<strong>The Bottom Line</strong>{" "}
							</h2>
							<p className="text-white">
								We as an executive coaching company use the Global Leadership
								Assessment 360 degree review program. Here are the practical
								tools and opportunities you will receive as a part of the
								program.
							</p>
							<p className="text-left">
								<ul className="checks-ul mt-5">
									<li className="list-checks">
										<p className="text-white">Unlimited Surveys for 1 Month</p>
									</li>
									<li className="list-checks">
										<p className="text-white">Feedback in 15 Competencies</p>
									</li>
									<li className="list-checks">
										<p className="text-white">
											60 Page Report Outlining Wins and Areas for Growth
										</p>
									</li>
									<li className="list-checks">
										<p className="text-white">6 Page Summary for Easy Review</p>
									</li>
									<li className="list-checks">
										<p className="text-white">
											Up to 4 Hours of One-on-One Coaching
										</p>
									</li>
									<li className="list-checks">
										<p className="text-white">
											Understand and Hone Your Core Values{" "}
										</p>
									</li>
									<li className="list-checks">
										<p className="text-white">
											Develop Your Roadmap: An Authentic Leadership Model{" "}
										</p>
									</li>
								</ul>
							</p>
							<h4 className="text-white mb-3">
								Please find the GLA360 Competencies along with sample reports
								and models below.
							</h4>
							<div class="pricing-table mt-3">
								<div class="pricing-option">
									<p>
										<h6 class="pricing-h">
											GLA360
											<br /> Competencies&nbsp;
										</h6>
									</p>

									<div class="price">
										<div class="back">
											<a
												class="bttn-b btn-prim-b btn-link-b"
												href={competencies}
												target="_blank"
												rel="noopener noreferrer"
												download
											>
												<span class="schedule-btn top-btn-sched sched-modal">
													Download
												</span>
											</a>
										</div>
									</div>
								</div>
								<div class="pricing-option">
									<p>
										<h6 class="pricing-h">
											Sample GLA360
											<br /> Values&nbsp;
										</h6>
									</p>

									<div class="price">
										<div class="back">
											<a
												class="bttn-b btn-prim-b btn-link-b"
												href={values}
												target="_blank"
												rel="noopener noreferrer"
												download
											>
												<span class="schedule-btn top-btn-sched sched-modal">
													Download
												</span>
											</a>
										</div>
									</div>
								</div>
								<div class="pricing-option">
									<p>
										<h6 class="pricing-h">
											Sample GLA360 Authentic Leadership Model
										</h6>
									</p>

									<div class="price">
										<div class="back">
											<a
												class="bttn-b btn-prim-b btn-link-b"
												href={leadership}
												target="_blank"
												rel="noopener noreferrer"
												download
											>
												<span class="schedule-btn top-btn-sched sched-modal">
													Download
												</span>
											</a>
										</div>
									</div>
								</div>

							</div>

						</div>
					</div>
				</div>
			</div>
		</div>
	</Layout>
)

export default ThreeSixtyReview
